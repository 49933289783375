import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import {
    of as observableOf,
    throwError as observableThrowError,
    Observable,
} from "rxjs";
import { AppConfigService } from "../app-config.service";

@Injectable({
    providedIn: "root",
})
export class ReportsService {
    public api: any;
    public httpAuthHeaders: any;

    constructor(
        private config: AppConfigService,
        private http: HttpClient,
        private localStorage: LocalStorage,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.api = config.load();
    }

    get(): Observable<any> {
        const url = `${this.api.base}v1/fixtures`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getPublic(): Observable<any> {
        const url = `${this.api.base}v1/fixtures`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: this.api.public_token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getFixtureInGroup(date: string): Observable<any> {
        const url = `${this.api.base}v1/fixtures/group?date=${date}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: this.api.public_token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getMedal(): Observable<any> {
        const url = `${this.api.base}v1/medals`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserMedal(userId: number): Observable<any> {
        const url = `${this.api.base}v1/medals?user_id=${userId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    add(data: any): Observable<any> {
        const url = `${this.api.base}v1/fixtures`;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        // tslint:disable-next-line: max-line-length
        const formData = `?a=0&sport_id=${data.sport_id}&category_id=${data.category_id}&type_id=${data.type_id}&date=${data.date}&time=${data.time}&name=${data.name}&stadium_id=${data.stadium_id}&group=${data.group}&users=${data.users}`;

        return this.http.put(url, formData, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    addResult(value: any, id: number): Observable<any> {
        const url = `${this.api.base}v1/fixtures/${id}/result`;

        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.patch(url, value, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getFixtureByCategory(id: number): Observable<any> {
        const url = `${this.api.base}v1/fixtures?category_id=${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getFixtureById(id: number): Observable<any> {
        const url = `${this.api.base}v1/fixtures/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: this.api.public_token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    edit(value: any): Observable<any> {
        const url = `${this.api.base}v1/fixtures/${value.id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + localStorage.getItem("user"),
            }),
        };

        // tslint:disable-next-line: max-line-length
        const formData = `?xe=0&type_id=${value.type_id}&date=${value.date}&time=${value.time}&name=${value.name}&stadium_id=${value.stadium_id}&group=${value.group}&users=${value.users}`;

        return this.http.patch(url, formData, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    delete(id): Observable<any> {
        const url = `${this.api.base}v1/fixtures/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.delete(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    /**
     * Handle error from the request.
     * @param error error handle
     */
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;

        if (error instanceof Response) {
            const body = error.json() || "";
            const err = JSON.stringify(body);
            console.log(err);
            errMsg = `${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError(errMsg);
    }
}
