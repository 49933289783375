import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomJsService } from 'src/app/shared/custom-js.service';

@Component({
    selector: 'app-sport-user-category',
    templateUrl: './sport-user-category.component.html',
    styleUrls: ['./sport-user-category.component.scss']
})
export class SportUserCategoryComponent implements OnInit {
    public userSports: any = null;
    public userSportsCategory: any = null;
    public userName: any = null;
    private userId: any;
    private sportId: any;

    constructor(
        private userService: UsersService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private customJsService: CustomJsService
    ) {}

    ngOnInit() {
        this.spinner.show();
        this.userId = this.route.snapshot.paramMap.get('user_id');
        this.sportId = this.route.snapshot.paramMap.get('sport_id');
        this.userService
            .getUserSportCategoryById(this.userId, this.sportId)
            .subscribe(res => {
                this.userSports = res;
                this.userName = res.username;
                this.userSportsCategory = res.categories;
                this.spinner.hide();

                setTimeout(() => {
                    this.initDataTable();
                }, 500);
            });
    }

    initDataTable() {
        const usersTable: any = document.getElementById('user-table');
        this.customJsService.dataTableDefault(usersTable);
    }

    delete(categoryId, atthleteId) {
        if (confirm('ต้องการที่จะลบผู้เข้าร่วมใช่ไหม? ')) {
            this.spinner.show();
            this.userService
                .deleteAthlet('', this.sportId, categoryId, atthleteId)
                .subscribe(res => {
                    this.ngOnInit();
                });
        }
    }
}
