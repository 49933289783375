import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AgePipe } from "./pipe/age.pipe";
import "moment/locale/th";
import { BuddishYearPipe } from "./pipe/buddish-year.pipe";
import { TimeformatPipe } from "./pipe/timeformat.pipe";
import { ScoreformatPipe } from "./pipe/scoreformat.pipe";
import { MeterconvertPipe } from "./pipe/meterconvert.pipe";
import { RunningFormatPipe } from "./pipe/runningForman.pipe";
import { CentimetersToMetersPipe } from "./pipe/distanceFormat.pipe";

@NgModule({
    declarations: [
        AgePipe,
        BuddishYearPipe,
        TimeformatPipe,
        ScoreformatPipe,
        MeterconvertPipe,
        RunningFormatPipe,
        CentimetersToMetersPipe,
    ],
    imports: [CommonModule],
    exports: [
        AgePipe,
        BuddishYearPipe,
        TimeformatPipe,
        ScoreformatPipe,
        MeterconvertPipe,
        RunningFormatPipe,
        CentimetersToMetersPipe,
    ],
})
export class SharedModule {}
