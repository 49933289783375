import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "RunningFormat",
})
export class RunningFormatPipe implements PipeTransform {
    transform(value: number): string {
        if (!value) {
            return "0:00:00";
        }

        // Convert milliseconds to seconds
        const totalSeconds = Math.floor(value / 1000);

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            return "-";
        }

        return `${hours}:${this.pad(minutes)}:${this.pad(seconds)}`;
    }

    private pad(num: number): string {
        return num < 10 ? "0" + num : num.toString();
    }
}
