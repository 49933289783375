import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class LoginStageService {
  private isLoggedIn = new BehaviorSubject<any>(null);
  public isLoggedIn$ = this.isLoggedIn.asObservable();

  constructor() {}

  getStage() {
    return this.isLoggedIn;
  }

  setStage(data) {
    this.isLoggedIn.next(data);
  }
}
