import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SportService } from 'src/app/sports/sport.service';
import { RouterModule } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
    declarations: [DashboardComponent],
    imports: [CommonModule, RouterModule, FullCalendarModule],
    providers: [SportService, DashboardService]
})
export class DashboardModule {}
