import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'age'
})
export class AgePipe implements PipeTransform {
    transform(value: any): any {
        switch (value) {
            case 1: {
                return 'รุ่นอายุ 12 ปี';
            }

            case 2: {
                return 'รุ่นอายุ 14 ปี';
            }

            case 3: {
                return 'รุ่นอายุ 16 ปี';
            }

            case 4: {
                return 'รุ่นอายุ 18 ปี';
            }
        }

        return value;
    }
}
