import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ReportsService } from "src/app/reports/reports.service";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import thLocale from "@fullcalendar/core/locales/th";

@Component({
    selector: "app-user-fixture",
    templateUrl: "./user-fixture.component.html",
    styleUrls: ["./user-fixture.component.scss"],
})
export class UserFixtureComponent implements OnInit {
    public fixtures: any = null;
    public calendarEvents = [];
    public isShowCalendar = false;
    public calendarPlugins: any;
    public isAthletics = false;
    public isRunning = false;

    constructor(
        private router: Router,
        private spinner: NgxSpinnerService,
        private reportsService: ReportsService,
        private customJsService: CustomJsService
    ) {
        this.spinner.show();
        this.reportsService.get().subscribe((res) => {
            this.fixtures = res.result;
            console.log(res.result);
            if (res.result.sport_id === 1) {
                console.log("runing");
                this.isAthletics = true;
                this.isRunning = false;
            }

            if (
                res.result.category_id <= 30 ||
                (res.result.category_id >= 38 &&
                    res.result.category_id <= 98) ||
                (res.result.category_id >= 247 &&
                    res.result.category_id <= 258) ||
                res.result.sport_id === 13
            ) {
                this.isAthletics = false;
                this.isRunning = true;
            }

            if (res.result.sport_id === 11 || res.result.sport_id === 12) {
                this.isAthletics = false;
                this.isRunning = false;
            }

            this.setEvents(res.result);
        });

        this.calendarPlugins = [dayGridPlugin, timeGridPlugin, listPlugin];
    }

    ngOnInit() {}

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    setEvents(data: any) {
        let eventTemplate = {
            title: "",
            start: "",
            color: "#f35e90", // Static
        };

        if (data === undefined || data.length === 0) {
            setTimeout(() => {
                this.spinner.hide();
            }, 500);

            return;
        }

        for (let i = 0; i < data.length; i++) {
            this.calendarEvents.push({
                title: `${data[i].category.sport_name}, ${data[i].category.name}, ${data[i].category.age_group_description} : ${data[i].name}, ${data[i].stadium_name}`,
                start: `${data[i].date}T${data[i].time}`,
                color: "#f35e90",
            });

            if (i === data.length - 1) {
                setTimeout(() => {
                    this.isShowCalendar = true;
                    this.customJsService.dataTableFixtures(".card-table");
                    this.spinner.hide();
                }, 500);
            }
        }
    }
}
