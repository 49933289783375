import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'meterconvert'
})
export class MeterconvertPipe implements PipeTransform {
    transform(value: any): any {
        return value / 100;
    }
}
