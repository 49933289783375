import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { BuddishYearPipe } from "src/app/shared/pipe/buddish-year.pipe";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { StaffsService } from "src/app/staffs/staffs.service";

@Component({
    selector: "app-user-sport-category",
    templateUrl: "./user-sport-category.component.html",
    styleUrls: ["./user-sport-category.component.scss"],
})
export class UserSportCategoryComponent implements OnInit {
    public token: any = null;
    public sport: any = null;
    public categories: any = null;
    public user: any = null;
    public isChrome = false;
    public timeout: any = null;
    public atheticCategories: any = null;
    public athletes: any = null;
    public currentCategory: any = null;

    constructor(
        private userService: UsersService,
        private localStorage: LocalStorage,
        private route: ActivatedRoute,
        private router: Router,
        private customJsService: CustomJsService,
        private buddishYearPipe: BuddishYearPipe,
        private deviceService: DeviceDetectorService,
        private spinner: NgxSpinnerService,
        private staffService: StaffsService
    ) {
        this.spinner.show();
        const device = this.deviceService.getDeviceInfo();
        const lowerName = device.browser.toLowerCase();
        this.isChrome = lowerName === "chrome";

        this.pageLoad();
    }

    pageLoad() {
        this.localStorage.getItem("user").subscribe((data) => {
            this.token = data;

            this.route.paramMap.subscribe((params: ParamMap) => {
                this.getSportCategory(params.get("id"));
            });

            this.userService.getUser(data).subscribe((res) => {
                this.user = res;
            });
        });
    }

    ngOnInit() {}

    getSportCategory(id) {
        this.userService
            .getUserSportCategory(this.token, id)
            .subscribe((res) => {
                this.sport = res;
                this.categories = res.categories;

                if (id === "1") {
                    this.prepreAthlectic();
                }

                setTimeout(() => {
                    this.customJsService.accordian();
                    this.spinner.hide();
                }, 300);
            });
    }

    goEdit(id) {}

    deleteAthlete(sportId, categoryId, athleteId) {
        this.spinner.show();
        if (confirm("ต้องการลบนักกีฬาใช่ไหม")) {
            this.userService
                .deleteAthlet(this.token, sportId, categoryId, athleteId)
                .subscribe((res) => {
                    this.pageLoad();
                    // this.router.navigate([`user/sport/${sportId}`]);
                });
        } else {
            console.log("ยกเลิก");
        }
    }

    deleteAthledeleteStafft(sportId, categoryId, staffId) {
        if (confirm("ต้องการลบนักกีฬาใช่ไหม")) {
            this.spinner.show();
            this.staffService
                .delete(sportId, categoryId, staffId)
                .subscribe((res) => {
                    this.pageLoad();
                });
        } else {
            console.log("ยกเลิก");
        }
    }

    prepreAthlectic() {
        const categories = Object.keys(this.sport.groupedCategories);
        const athletes = this.sport.categories.flatMap((category) =>
            category.athletes.map((athlete) => {
                return {
                    name: `${athlete.first_name} ${athlete.last_name}`,
                    gender: category.gender_id,
                };
            })
        );

        this.atheticCategories = categories;
        this.athletes = athletes;
    }

    printAthleticsSheet() {
        const url = `${window.location.origin}/#/user/sport/print/${this.sport.id}`;
        console.log(url);
        window.open(url);
    }

    printPhoto(data) {
        this.currentCategory = data;
        this.spinner.show();
        const athletes = data.athletes;
        const sportCategoryNameEle = document.getElementById("sportCategory");
        const printContentArea = document.getElementById("print-content-area");
        printContentArea.innerHTML = "";

        sportCategoryNameEle.innerText = `${data.name} รุ่นอายุ ${data.age_group_description} (${data.gender_description}) `;

        for (let i = 0; i < athletes.length; i++) {
            const birthDate = this.buddishYearPipe.transform(athletes[i].dob);
            const dataElement = `<div class="col-md-3 content-user text-center">
                <img src="${athletes[i].image}" alt="" />
                <p class="athlete-text">
                    ${athletes[i].first_name}<br />
                    ${athletes[i].last_name}<br />
                    ${birthDate}
                </p>
            </div>`;

            const isLast = i === athletes.length - 1;
            this.insertElement(dataElement, isLast, data);
        }
    }

    insertElement(dataElement, isLast, data) {
        const printContentArea = document.getElementById("print-content-area");
        if (printContentArea) {
            printContentArea.innerHTML += dataElement;
        }

        if (isLast) {
            setTimeout(() => {
                this.insertTableElement(data);
            }, 1000);
        }
    }

    insertTableElement(data) {
        const athletes = data.athletes;
        let noUser = 1;
        const printContentArea = document.getElementById("table-print-id");
        printContentArea.innerHTML = "";

        for (let i = 0; i < athletes.length; i++) {
            const birthDate = this.buddishYearPipe.transform(athletes[i].dob);
            // tslint:disable-next-line:max-line-length
            const dataElement = `<tr><td>${noUser}</td><td>${athletes[i].first_name} ${athletes[i].last_name}</td><td>${birthDate}</td><td>${athletes[i].age.year} ปี ${athletes[i].age.month} เดือน</td><td>${athletes[i].education}</td><td></td><td></td></tr>`;

            noUser++;
            const isLast = i === athletes.length - 1;
            this.addTableElement(dataElement, isLast, data);
        }
    }

    addTableElement(dataElement, isLast, data) {
        const printContentArea = document.getElementById("table-print-id");
        if (printContentArea) {
            printContentArea.innerHTML += dataElement;
        }

        if (isLast) {
            setTimeout(() => {
                this.prepareApplication(data);
            }, 800);
        }
    }

    prepareApplication(data) {
        const athletes = data.athletes;
        const printContentAreaApplication = document.getElementById(
            "print-content-area-application"
        );
        printContentAreaApplication.innerHTML = "";

        for (let i = 0; i < athletes.length; i++) {
            const birthDate = this.buddishYearPipe.transform(athletes[i].dob);

            const dataElement = this.formTemplate(athletes[i], birthDate, data);
            const isLast = i === athletes.length - 1;
            this.renderApplication(dataElement, isLast, data);
        }
    }

    renderApplication(dataElement, isLast, data) {
        const printContentAreaApplication = document.getElementById(
            "print-content-area-application"
        );
        if (printContentAreaApplication) {
            printContentAreaApplication.innerHTML += dataElement;
        }

        if (isLast) {
            setTimeout(() => {
                this.prepareComfirm(data);
            }, 1000);
        }
    }

    prepareComfirm(data) {
        const athletes = data.athletes;
        const printContentAreaConfirm = document.getElementById(
            "print-content-area-confirm"
        );

        printContentAreaConfirm.innerHTML = "";

        for (let i = 0; i < athletes.length; i++) {
            const birthDate = this.buddishYearPipe.transform(athletes[i].dob);

            const dataElement = this.confirmTemplate(
                athletes[i],
                birthDate,
                data
            );
            const isLast = i === athletes.length - 1;
            this.renderConfirm(dataElement, isLast);
        }
    }

    renderConfirm(dataElement, isLast) {
        const printContentAreaConfirm = document.getElementById(
            "print-content-area-confirm"
        );
        if (printContentAreaConfirm) {
            printContentAreaConfirm.innerHTML += dataElement;
        }

        if (isLast) {
            setTimeout(() => {
                this.spinner.hide();
                window.print();
            }, 1000);
        }
    }

    confirmTemplate(athlete, birthDate, data) {
        return ` <div class="page-container">
        <div class="document-number document-number-noborder">เอกสารหมายเลข ๓</div>
        <div class="print-header confirm-header">
            <div class="confirm-avatar-con">
                <img src="${athlete.image}"
                alt=""
                class="confirm-avatar-avatar"/>
                <p class="text-center admin-sign">
                        (ลงชื่อ).......................................
                    <br />
                    (............................................)
                    <br />
                    นายทะเบียนโรงเรียน เป็นผู้ลงนาม<br>
                    รับรองรูปถ่ายและประทับตราโรงเรียน

                </p>
            </div>
            <h2 class="text-center">
                    ใบรับรอง
            </h2>
            <p class="text-center sub-header">
                    โรงเรียน${athlete.school}<br>
                    องค์กรปกครองส่วนท้องถิ่น ${this.user.name}
                    <br>
                    อำเภอ ${this.user.district} จังหวัด ${this.user.province}
                    <br>
                    ใบรับรองฉบับนี้ให้ไว้เพื่อแสดงว่า
            </p>
        </div>
        <div class="confirm-content">
          <p>
                ตามรูปถ่ายข้างบนนี้  คือ ${athlete.first_name} ${
            athlete.last_name
        } อายุ ${
            athlete.age.year
            // tslint:disable-next-line:max-line-length
        } ปี ปัจจุบันกำลังศึกษาอยู่ในสถานศึกษาแห่งนี้ เลขประจำตัว ...........................เกิดวันที่ ${birthDate} เลขหมายประจำตัวประชาชน ${
            athlete.id_card_number
        } เข้าเรียนในสถานศึกษาแห่งนี้ เมื่อวันที่.............เดือน........................พ.ศ................ ขณะนี้กำลังศึกษาอยู่ชั้น ${
            athlete.education
        } ของสถานศึกษาแห่งนี้

          </p>
          <div class="sign-con">
                <p class="text-center admin-sign table-form">
                    (ลงชื่อ).......................................
                    <br />
                    (............................................)
                    <br />
                    ครูประจำชั้น
                </p>
                <p class="text-center admin-sign table-form">
                        (ลงชื่อ).......................................
                    <br />
                    (............................................)
                    <br />
                    ผอ. สถานศึกษา<br />
                     ผอ.กอง / หน.กอง    
                </p>
                <p class="text-center admin-sign table-form">
                        (ลงชื่อ).......................................
                    <br />
                    (............................................)
                    <br />
                    ผอ.สำนัก / ผอ.เขต
                   
                </p>
            </div>
        </div>
        <div class="print-footer confirm">
                <strong>หมายเหตุ</strong> ให้ผู้เกี่ยวข้องตรวจสอบคุณสมบัติ เช่น อายุ รูปถ่าย ฯลฯ ให้ถูกต้องตามหลักเกณฑ์การ
                แข่งขันก่อนลงนามตามลำดับ
        </div>
    </div>`;
    }

    formTemplate(data, birthDate, sportData) {
        // tslint:disable-next-line:max-line-length
        return `<div class="page-container" ><div class="print-header">
        <h2 class="text-center">
            ใบสมัครเข้าร่วมแข่งขันกีฬา ${this.sport.name}<br />
            <span id="category-name">${sportData.name}</span>
            <span id="category-age-name">${
                sportData.age_group_description
            }</span> <span id="category-age-name">${
            sportData.gender_description
        }</span><br/>***********************
        </h2>
    </div>
    <div class="print-content">
    <div class="document-number document-number-noborder">เอกสารหมายเลข ๒</div>
    <div class="application-avatar" style="height: 150px !important; overflow: hidden;">
    <img
        src="${data.image}"
        alt=""
        class=""
    />
    </div>
        <div class="application-content" id="">
            <span class="l1-1">ข้าพเจ้า <span>${data.first_name} ${
            data.last_name
        } </span></span>
            <span class="l1-2">เกิดวันที่ <span>${birthDate}</span></span>
            <span class="l2-1">อายุ <span>${data.age.year} ปี</span></span>
            <span class="l2-2">หมายเลขประจำตัวประชาชน <span>${
                data.id_card_number
            }</span></span>
            <span class="l3-1">ชื่อบิดา <span>${data.father_first_name} ${
            data.father_last_name
        }</span></span>
            <span class="l3-2">ชื่อมารดา <span>${data.mother_first_name} ${
            data.mother_last_name
        }</span></span>
            <span class="l4-1">อาศัยอยู่บ้านเลขที่​ <span>${
                data.house_no
            }</span></span>
            <span class="l4-2">ซอย <span>-</span> </span>
            <span class="l4-3">ถนน <span>${data.street}</span> </span>
            <span class="l5-1">ตำบล/แขวง <span>${
                data.subdistrict
            }</span> </span>
            <span class="l5-2">อำเภอ/เขต <span>${data.district}</span> </span>
            <span class="l6-1">จังหวัด <span>${data.province}</span></span>
            <span class="l6-2">รหัสไปรษณีย์ <span>${
                data.postal_code
            }</span></span>
            <span class="l6-3">โทรศัพท์ <span>${data.tel}</span> </span>
            <span class="l7-1">ปัจจุบันกำลังศึกษาอยู่ระดับชั้น <span>${
                data.education
            }</span></span>
            <span class="l7-2">โรงเรียน <span>${data.school}</span> </span>
            <span class="l8-1">สังกัด อปท <span>${this.user.name}</span> </span>
            <span class="l8-2">อำเภอ <span>${this.user.district}</span> </span>
            <span class="l9-1">จังหวัด <span>${
                this.user.province
            }</span> </span>
            <span class="l9-2">โทรศัพท์ <span>-</span> </span>
            <p class="second-p">
            ขอสมัครเข้าร่วมการแข่งขันกีฬา
            ${this.sport.name}
            ในการแข่งขันกีฬานักเรียนองค์กรปกครองส่วนท้องถิ่นแห่งประเทศไทย
            รอบคัดเลือก ระดับภาคเหนือ ครั้งที่ 39 ประจำปีการศึกษา 2567
            ระหว่างวันที่ 13 - 22  ธันวาคม  2567
            ซึ่งจัดการแข่งขันโดยเทศบาลนครนครสวรรค์ ด้วยความสมัครใจ
            หากมีสิ่งหนึ่งสิ่งใดที่เกิดขึ้นระหว่างการแข่งขันโดยมิอาจคาดคิดมาก่อน
            ข้าพเจ้าจะไม่ขอเรียกร้องใดๆ ทั้งสิ้น
            <p class="text-right admin-sign table-form">
                (ลงชื่อ)...............................................................
                (ผู้สมัคร)
                <br />
                (.......................................................................................)
                <br />
                วันที่
                .............เดือน.............................พ.ศ.........................
            </p>
            </p>
        </div>
    </div>
    <div class="print-footer">
        <hr />
        <h3 class="text-center">
            <strong>คำรับรองและคำยินยอมของผู้ปกครอง</strong>
        </h3>
        <p>
            ข้าพเจ้า
            .........................................................ในฐานะเป็นผู้ปกครองของ ${
                data.first_name
            } ${data.last_name} ${" "}
            ขอรับรองว่าข้อความดังกล่าวข้างต้นเป็นความจริงทุกประการ
            และยินดีอนุญาตให้ ${data.first_name} ${data.last_name} ${" "}
            เข้าร่วมแข่งขันกีฬา <strong>${this.sport.name}</strong>
            ในการจัดการแข่งขันกีฬานักเรียนองค์กรปกครองส่วนท้องถิ่นแห่งประเทศครั้งที่
            39 ประจำปีการศึกษา 2567 รอบคัดเลือกระดับภาคเหนือ
            ระหว่างวันที่ 13-22 ธันวาคม 2567 ซึ่งจัดการแข่งขันโดย
            เทศบาลนครนครสวรรค์ 
            หากมีสิ่งใดที่เกิดขึ้นระหว่างการแข่งขันโดยมิอาจคาดคิดมาก่อน
            ข้าพเจ้าจะไม่ขอเรียกร้องใด ๆ ทั้งสิ้น
        </p>
        <p class="text-center admin-sign table-form">
            (ลงชื่อ)...............................................................
            (ผู้ปกครอง)
            <br />
            (.......................................................................................)
        </p>
    </div></div>`;
    }
}
