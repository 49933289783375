import { HttpClientModule, HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { UsersService } from "./users.service";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AppConfigService } from "../app-config.service";
import { UsersComponent } from "./users/users.component";
import { UserRoutingModule } from "./user-routing.module";
import { UserSportComponent } from "./user-sport/user-sport.component";
import { UserSportCategoryComponent } from "./user-sport-category/user-sport-category.component";
import { SharedModule } from "../shared/shared.module";
import { BuddishYearPipe } from "../shared/pipe/buddish-year.pipe";
import { AllUsersComponent } from "./all-users/all-users.component";
import { SportOfUserComponent } from "./sport-of-user/sport-of-user.component";
import { SportUserCategoryComponent } from "./sport-user-category/sport-user-category.component";
import { UsersAccommodationsComponent } from "./users-accommodations/users-accommodations.component";
import { UserFixtureComponent } from "./user-fixture/user-fixture.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { UserSportCategoryPrintComponent } from "./user-sport-category-print/user-sport-category-print.component";

@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        UsersComponent,
        UserSportComponent,
        UserSportCategoryComponent,
        AllUsersComponent,
        SportOfUserComponent,
        SportUserCategoryComponent,
        UsersAccommodationsComponent,
        UserFixtureComponent,
        UserSportCategoryPrintComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        FullCalendarModule,
        ReactiveFormsModule,
        HttpClientModule,
        UserRoutingModule,
    ],
    providers: [UsersService, AppConfigService, BuddishYearPipe],
})
export class UsersModule {}
