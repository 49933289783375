import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfigService {
  private config: any;

  constructor(private http: HttpClient) {}

  /**
   * load configurations from `config.json` (placed at root directory of the project)
   * or throw an error if the config file is missing.
   */
  load() {
    return environment;
    // return this.http
    //   .get(`/config.json`)
    //   .toPromise()
    //   .then(config => {
    //     this.config = config;
    //     this.config.endpoints.base = environment.api;
    //   })
    //   .catch(e => {
    //     console.log(e);
    //     throw new Error('Cannot load configuration file.');
    //   });
  }

  getAll(): any {
    return this.config;
  }
}
