import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { UsersService } from "../users.service";
import { LoginStageService } from "./../../shared/login-stage.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
    @Output() loginUser = new EventEmitter();

    public loginData: any;
    public hasError = false;
    public errorMsg: any;
    public loggedIn: any = false;
    public user: any = null;

    constructor(
        private userService: UsersService,
        private formBuilder: FormBuilder,
        private loginStageService: LoginStageService,
        private router: Router,
        private localStorage: LocalStorage,
        private spinner: NgxSpinnerService
    ) {
        this.spinner.show();
        this.loginStageService.isLoggedIn$.subscribe(
            (data) => (this.loggedIn = data)
        );

        this.loginData = this.formBuilder.group({
            username: [
                "",
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(50),
                ]),
            ],
            password: ["", Validators.required],
        });

        setTimeout(() => {
            this.spinner.hide();
        }, 500);
    }

    ngOnInit() {}

    /**
     * Send login request
     *
     * @param formData any
     */
    login(formData) {
        this.userService.login(this.loginData.value).subscribe((data) => {
            if (data !== null) {
                // tslint:disable-next-line: no-string-literal
                this.userService.storeToken(data["access_token"]);
                this.loginStageService.setStage(true);
                // tslint:disable-next-line: no-string-literal
                this.getUser(data["access_token"]);
            }
        });
    }

    getUser(token) {
        localStorage.setItem("user", token);
        this.userService.getUser(token).subscribe((res) => {
            const userData: any = {
                id: res.id,
                name: res.name,
                image: res.image,
            };

            localStorage.setItem("_user", JSON.stringify(userData));
            this.localStorage.setItem("_user", userData).subscribe((data) => {
                this.router.navigate(["/dashboard"]).then(() => {
                    window.location.reload();
                });
            });
        });
    }
}
