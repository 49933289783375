import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import {
    of as observableOf,
    throwError as observableThrowError,
    Observable
} from 'rxjs';
import { AppConfigService } from '../app-config.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    public api: any;

    constructor(
        private config: AppConfigService,
        private http: HttpClient,
        private localStorage: LocalStorage,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.api = this.config.load();
    }

    getDashboard(): Observable<any> {
        const url = `${this.api.base}v1/dashboard`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('user')}`
            })
        };

        return this.http.get(url, httpOptions).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getMedal(): Observable<any> {
        const url = `${this.api.base}v1/medals`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('user')}`
            })
        };

        return this.http.get(url, httpOptions).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    getFixtures(): Observable<any> {
        const url = `${this.api.base}v1/fixtures`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('user')}`
            })
        };

        return this.http.get(url, httpOptions).pipe(
            map(res => res),
            catchError(this.handleError)
        );
    }

    /**
     * Handle error from the request.
     * @param error error handle
     */
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;

        if (error instanceof Response) {
            const body = error.json() || '';
            const err = JSON.stringify(body);
            console.log(err);
            errMsg = `${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError(errMsg);
    }
}
