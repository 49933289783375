import { UsersAccommodationsComponent } from "./users-accommodations/users-accommodations.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { UsersComponent } from "./users/users.component";
import { UserSportComponent } from "./user-sport/user-sport.component";
import { UserSportCategoryComponent } from "./user-sport-category/user-sport-category.component";
import { UnauthorizedComponent } from "../errorpage/unauthorized/unauthorized.component";
import { NotfoundComponent } from "../errorpage/notfound/notfound.component";
import { AllUsersComponent } from "./all-users/all-users.component";
import { NgxPermissionsGuard } from "ngx-permissions";
import { SportOfUserComponent } from "./sport-of-user/sport-of-user.component";
import { SportUserCategoryComponent } from "./sport-user-category/sport-user-category.component";
import { AuthGuardService as AuthGuard } from "../auth/auth-guard.service";
import { UserFixtureComponent } from "./user-fixture/user-fixture.component";
import { GusetGuardService } from "../auth/guest-guard.service";
import { UserSportCategoryPrintComponent } from "./user-sport-category-print/user-sport-category-print.component";
const routes: Routes = [
    {
        path: "",
        component: UserSportComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "all",
        canActivate: [NgxPermissionsGuard],
        component: AllUsersComponent,
        data: {
            permissions: {
                only: "ADMIN",
                redirectTo: "/404",
            },
        },
    },
    {
        path: "sport-user/:id",
        canActivate: [NgxPermissionsGuard],
        component: SportOfUserComponent,
        data: {
            permissions: {
                only: "ADMIN",
                redirectTo: "/404",
            },
        },
    },
    {
        path: "sport-user-category/:user_id/:sport_id",
        canActivate: [NgxPermissionsGuard],
        component: SportUserCategoryComponent,
        data: {
            permissions: {
                only: "ADMIN",
                redirectTo: "/404",
            },
        },
    },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [GusetGuardService],
    },
    {
        path: "sport",
        component: UserSportComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "sport/:id",
        component: UserSportCategoryComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "sport/print/:id",
        component: UserSportCategoryPrintComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "accommodation",
        component: UsersAccommodationsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "fixture",
        component: UserFixtureComponent,
        canActivate: [AuthGuard],
    },
    { path: "500", redirectTo: "/500" },
    { path: "401", redirectTo: "/402" },
    { path: "404", redirectTo: "/404" },
    { path: "", redirectTo: "/dashboard", pathMatch: "full" },
    { path: "**", redirectTo: "/404" },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule {}
