import { Component, OnInit } from '@angular/core';
import { UsersService } from './../users.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    public users: any = null;

    constructor(private userService: UsersService) {
        this.userService.getUsers().subscribe(data => {
            this.users = data;
        });
    }

    ngOnInit() {}
}
