import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import {
    of as observableOf,
    throwError as observableThrowError,
    Observable,
} from "rxjs";
import { AppConfigService } from "../app-config.service";

@Injectable({
    providedIn: "root",
})
export class StaffsService {
    public api: any;

    constructor(
        private config: AppConfigService,
        private http: HttpClient,
        private localStorage: LocalStorage,
        private router: Router,
        private toastr: ToastrService
    ) {
        this.api = this.config.load();
    }

    getStaff(): Observable<any> {
        const url = `${this.api.base}v1/user/staffs`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getById(id: any): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    add(data, sportId, categoryId): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${sportId}/categories/${categoryId}/staffs`;

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.put(url, data, httpOptions).pipe(
            map((res) => res),
            catchError((err) => this.handleError)
        );
    }

    edit(value: any): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/${value.data.id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("user"),
            }),
        };

        return this.http.patch(url, value, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    uploadPhoto(file, id): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        const formData = new FormData();
        formData.append("img", file);

        return this.http.post(url, formData, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    delete(sportId, categoryId, id): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${sportId}/categories/${categoryId}/staffs/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.delete(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    deleteStaff(id: number): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.delete(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    checkExists(idCard: number, categoryId: number): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/checkexists?id=${idCard}&category_id=${categoryId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    checkById(idCard: number): Observable<any> {
        const url = `${this.api.base}v1/user/staffs/byidcard/${idCard}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    /**
     * Handle error from the request.
     * @param error error handle
     */
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;

        if (error instanceof Response) {
            const body = error.json() || "";
            const err = JSON.stringify(body);
            console.log(err);
            errMsg = `${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return observableThrowError(errMsg);
    }
}
