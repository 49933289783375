import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { LoginStageService } from './../../shared/login-stage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public loggedIn: boolean;

  constructor(
    private userService: UsersService,
    private loginStageService: LoginStageService
  ) {
    this.loginStageService.isLoggedIn$.subscribe(data => {
      this.loggedIn = data;
    });
  }

  ngOnInit() {}

  logout() {
    this.loginStageService.setStage(false);
    this.userService.logout();
  }
}
