import { UsersService } from './../users.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorage } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-users-accommodations',
  templateUrl: './users-accommodations.component.html',
  styleUrls: ['./users-accommodations.component.scss']
})
export class UsersAccommodationsComponent implements OnInit {
  public accommodation: any = null;
  public accommodationData: any = null;
  public user: any = null;

  constructor(
    private usersService: UsersService,
    private spinner: NgxSpinnerService,
    private localStorage: LocalStorage,
  ) {
    this.spinner.show();

    this.localStorage.getItem('_user').subscribe(data => {
      this.user = data;
    });

    this.usersService.getUserAccommodation().subscribe(
      res => {
        this.accommodation = res.result;
        this.getAccommodateDetail(res.result.id);
      },
      err => {
        this.accommodation = null;

        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      });
  }

  ngOnInit() {
  }

  getAccommodateDetail(id: number) {
    this.usersService.getAccommodationDetail(id, this.user.id).subscribe(res => {
      this.accommodationData = res.result.users[0];

      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    });
  }

}
