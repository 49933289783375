import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "centimetersToMeters",
})
export class CentimetersToMetersPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return "-";
        }

        const centimeters = parseFloat(value);
        if (isNaN(centimeters)) {
            return "-";
        }

        const meters = centimeters / 100;
        return meters.toFixed(2); // Format to 2 decimal places
    }
}
