import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorage } from "@ngx-pwa/local-storage";

@Injectable({
    providedIn: "root",
})
export class TokenService {
    constructor(private localStorage: LocalStorage, private router: Router) {}

    getToken() {
        this.localStorage.getItem("user").subscribe((user) => {
            return user;
        });
    }

    clearToken() {
        localStorage.clear();
        this.localStorage.clear().subscribe(() => {
            this.router.navigate(["/login"]);
        });
    }

    storeToken(token) {
        if (token === null || token === "") {
            this.clearToken();
            return;
        }

        this.localStorage.setItem("user", token).subscribe(() => {
            this.router.navigate(["/dashboard"]);
        });
    }

    getUserData() {
        const user = this.localStorage.getItem("user");

        if (user === null || user === undefined) {
            this.clearToken();
            return;
        }

        return this.localStorage.getItem("user");
    }
}

export function tokenGetter() {
    return localStorage.getItem("user");
}
