import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'buddishYear'
})
export class BuddishYearPipe implements PipeTransform {
    transform(value: any): any {
        const thaiMonth = [
            '',
            'มกราคม',
            'กุมภาพันธ์',
            'มีนาคม',
            'เมษายน',
            'พฤษภาคม',
            'มิถุนายน',
            'กรกฎาคม',
            'สิงหาคม',
            'กันยายน',
            'ตุลาคม',
            'พฤศจิกายน',
            'ธันวาคม'
        ];

        const dateValue = value.split('-', 3);
        const mountIndex = parseInt(dateValue[1], 10);
        const buddishYear = parseInt(dateValue[0], 10) + 543;
        return `${dateValue[2]} ${thaiMonth[mountIndex]} ${buddishYear}`;
    }
}
