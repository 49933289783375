// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    whitelist: ["https://api.siamplus.co.th/"],
    api: "https://api.siamplus.co.th/v1/",
    base: "https://api.siamplus.co.th/",
    public_token:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNzM4YTg2NjEyNjFjOTc1MTUzYWIyMzY0OTM0NTc0Y2Q1MTQ1MTdlNzRkYWNiYWZmY2Q2ZDgyNGI3MzQ3NjRhYzc3NjRjMmVlNjVmYTFmZWYiLCJpYXQiOjE3MzExMzQ1MDYuNDQxNjEyLCJuYmYiOjE3MzExMzQ1MDYuNDQxNjE1LCJleHAiOjE3NjI2NzA1MDYuNDI5NzQ5LCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.HOzctJvbGngx-naswhM-1ObVsJjGXaUSO9qVz84LYnfh6PvQ9MaZyFNmqz2hMmMmzF48ES8O8NfLpRqGAarsGhl6dTvtbtSYDrJj2CBfu94zoPeR8r479-lhYu5xy8bL16T6-rnClclpYDWLja0n0-fkMCRRihG81w3B0gM6hjPak16xwID1upFLD3he1OFFPvXNAjaX9Fj2bJXg1J0_LmxyE4fhvkYinGcHI22lDVpPFSv2nxe1XUekHqDlOKjRg8--R1u8E0lzOyJ_Yuk0qj01tIVcHPcM9gtIBkzXnEHp7avdgQ54hc250iakrUHSjVZ5bqetBiot_micZvFDz4IN5xxZFZbpJbstFDUBCtZsPFlLP3LR9FjYUyf7fvM-8N-1IqlUzRopuwry6q24HpGmwkV1v3NqhzkJKkwTqYFX4gnnQwq2eAR5LhWwHC9kiLPorP1dGktjYJVUkR1AeCsv68vXpK-aH-nQT7iXgxOAgyVYzK4SBlAr5QPUCodXNe7oYno-rBUS7fRnjWTN2NotMJ7bsJ4bEWlSin4CG1-0GVuiIERYK-YIwOMBUayZYxEZEIzzTdQd99xZeTpL7ju2sU0mUx8SyPAbuQQ8A9_3A5mgJy_BpFEJMxNyCYxwWjlrpIwoSxRKKYHeRnRe8w1wqGL-eeB9O-hHXYHOSPQ",
    // whitelist: ["https://api.nakhonsawan-pasangames.com/"],
    // api: "https://api.nakhonsawan-pasangames.com/v1/",
    // base: "https://api.nakhonsawan-pasangames.com/",
    // public_token:
    //     "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzU1ZjhhMDUwZGE4NTA5NDYwZmQ3YWZlODc3NDI2MTRmYzQyY2U1YzM1NjBlNzdkYzFjZmM0YmMxYjFmZWI5MjlhNGVkZTQ1MTA1MzM1ZGYiLCJpYXQiOjE3Mjk0MDA3MTkuODM1MTQ4LCJuYmYiOjE3Mjk0MDA3MTkuODM1MTUxLCJleHAiOjE3NjA5MzY3MTkuODI3NTU0LCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.d95BSPMP9CwQrNfs5heT92vF2imGXdlkMk3sU_7c1JnNpmoHnkB9tJ7AiFXrNpIPMAUjzPPuGJyOWgbeC7nHrcczSKl-E_YF10YPZXBsdjhc-ClU1y0kHBRBh-nNYd0JMAh2eZjrxHWGc_PHIFbaXgIIaZh-vAsWsE9HYWEsON-ZLbyWGilYLtyRzHmxd979AqsfAqrPYwmDawW6tKrqXAlbDHcRoQuF7UgOpiWGvu2YwYl966vmn_onFIjBGRuZEOusfBdyWCoZpnchX8W3dT1TR08YQtCkDwP1Y1o6sstHmOYsdZ5IGfJ2Gq-i01pJ2eK4Ei8fNpvcHslGiQ1fsHj04EBKWXqfD3MLqbQgC_EbH6F1OESMf8Ly7q4i0nEhxo59cNdr0pVKUc7jAi-r0kjMmv-hd0GrYCPdoB3a6M3w5AIEDJhOboGRMlGK6FHJlUlklIxLHZyc3yIuHDxY10ffiTQpL3SJLvabZwM7QXS5roP_OmkiFbhf1ESFCEzQBKilh-HrqPcft33qiMnddTZUxVgxg6y04I6yg_eOXpMUx-4fQqT7KIZz9r7umszMDB6n2PKdvXLNZtlf-eyg_gVfnoqjMbUrnfZ2XUZT1kQTEgroGnQLfs2RGtRU3SGB_xNn5WkPGdqknnCzEN49rDIwKi3qHrQGKGJVa-YS4qM",
    grant_type: "password",
    client_id: 1,
    client_secret: "AiDEuKRotDn9uDbdOewzhpjEHHtERMUCSM5eNgoE",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
