import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scoreformat'
})
export class ScoreformatPipe implements PipeTransform {
    transform(value: number): any {
        const pad = (n, z = 2) => ('00' + n).slice(-z);
        return (
            pad((value / 3.6e6) | 0) +
            ':' +
            pad(((value % 3.6e6) / 6e4) | 0) +
            ':' +
            pad(((value % 6e4) / 1000) | 0) +
            '.' +
            pad(value % 1000, 3)
        );
    }
}
