import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "timeformat",
})
export class TimeformatPipe implements PipeTransform {
    transform(value: any): any {
        const newStr = value.slice(0, -3);
        return `${newStr} น.`;
    }
}
