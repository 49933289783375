import { Component, OnInit } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { UsersService } from '../users.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-user-sport',
    templateUrl: './user-sport.component.html',
    styleUrls: ['./user-sport.component.scss']
})
export class UserSportComponent implements OnInit {
    public token: any = null;
    public user: any = null;
    public userSports: any = null;

    constructor(
        private localStorage: LocalStorage,
        private userService: UsersService,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
        this.spinner.show();

        this.localStorage.getItem('user').subscribe(data => {
            this.token = data;
            this.getUserSport(this.token);
        });

        this.localStorage.getItem('_user').subscribe(data => {
            this.user = data;
        });
    }

    ngOnInit() {}

    getUserSport(token) {
        this.userService.getUserSports(token).subscribe(res => {
            this.userSports = res;

            setTimeout(() => {
                this.spinner.hide();
            }, 500);
        });
    }

    goDetail(id) {
        this.router.navigate(['/user/sport/' + id]);
    }
}
