import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomJsService } from 'src/app/shared/custom-js.service';

@Component({
    selector: 'app-sport-of-user',
    templateUrl: './sport-of-user.component.html',
    styleUrls: ['./sport-of-user.component.scss']
})
export class SportOfUserComponent implements OnInit {
    public userSports: any = null;
    public userId: any = null;
    public userName: string = null;

    constructor(
        private userService: UsersService,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private customJsService: CustomJsService
    ) {
        this.spinner.show();
        const param: any = this.route.snapshot.paramMap.get('id');
        this.userId = param;
        this.userService.getUserSportsById(param).subscribe(res => {
            this.userSports = res;
            this.userName = res[0].username;
            this.spinner.hide();

            setTimeout(() => {
                this.initDataTable();
            }, 500);
        });
    }

    ngOnInit() {}

    initDataTable() {
        const usersTable: any = document.getElementById('user-table');
        this.customJsService.dataTableDefault(usersTable);
    }
}
